import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";

const Numbers = (props) => {
  const getNumbers = () => {
    return Object.keys(props.numbers.rows).map((key, index) => (
      <div className="casehistory-numbers" key={index}>
        <Row className="align-items-end">
          {props.numbers.rows[key][0].number.length ? (
            <Col md="6" className="ms-lg-auto">
              <div className="number">{props.numbers.rows[key][0].number}</div>
              {props.numbers.rows[key][0].value}
            </Col>
          ) : null}
          {props.numbers.rows[key][1].number.length ? (
            <Col md="6" className="ms-lg-auto">
              <div className="number">{props.numbers.rows[key][1].number}</div>
              {props.numbers.rows[key][1].value}
            </Col>
          ) : null}
        </Row>
      </div>
    ));
  };
  return <Container>{getNumbers()}</Container>;
};

Numbers.propTypes = {
  numbers: PropTypes.object.isRequired,
};

export default Numbers;
