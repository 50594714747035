import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Image = (props) => {
  const imageStyle = {
    maxWidth: props.width,
    maxHeight: props.height,
    objectFit: "cover",
    objectPosition: "center",
  };

  if (!props.isVisible) {
    return null;
  } else if (props.isFull) {
    return (
      <div className="case-history-image-full">
        {props.imageUrl !== "" ? (
          <GatsbyImage
            className={props.className}
            imgStyle={imageStyle}
            image={getImage(props.imageUrl)}
            alt={props.alt}
          />
        ) : null}
      </div>
    );
  } else if (props.isMobile) {
    return (
      <div className="case-history-image">
        {props.imageUrl !== "" ? (
          <GatsbyImage
            className={props.className}
            imgStyle={imageStyle}
            image={getImage(props.imageUrl)}
            alt={props.alt}
          />
        ) : null}
      </div>
    );
  } else {
    return (
      <Container fluid={props.isFluid}>
        <Row>
          <Col md="5" />
          <Col md="7">
            <div className="case-history-image">
              {props.imageUrl !== "" ? (
                <GatsbyImage
                  className={props.className}
                  imgStyle={imageStyle}
                  image={getImage(props.imageUrl)}
                  alt={props.alt}
                />
              ) : null}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
};

Image.propTypes = {
  isVisible: PropTypes.bool,
  isFull: PropTypes.bool,
  isMobile: PropTypes.bool,
  isFluid: PropTypes.bool,
  className: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  alt: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Image;
