import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import PropTypes from "prop-types";

const Description = (props) => {
  return (
    <Container fluid>
      <div className="casehistory-description">
        <Row>
          <Col md="6">{documentToReactComponents(JSON.parse(props.col1))}</Col>
          <Col md="6">{documentToReactComponents(JSON.parse(props.col2))}</Col>
        </Row>
      </div>
    </Container>
  );
};

Description.propTypes = {
  col1: PropTypes.object,
  col2: PropTypes.object,
};

export default Description;
