import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import arrowDownIcon from "../../../../assets/images/hevelop/arrow-down.svg";
import { Image } from "../Image";
import PropTypes from "prop-types";

const Details = (props) => {
  const headingTitle = (
    <div className="heading">
      <h1>{props.title}</h1>
    </div>
  );

  const details = Object.keys(props.details).map((key, i) => (
    <div className="detail-item" key={i}>
      <p>
        <strong>{props.details[key].key}</strong>
      </p>
      <p>{props.details[key].value}</p>
    </div>
  ));

  return (
    <div className="details-wrapper">
      <div className="mobile-top-wrapper d-block d-lg-none">
        <div className="container-fluid">{headingTitle}</div>
        <Image
          isVisible={true}
          isMobile={true}
          isFull={true}
          width={786}
          height={786}
          className="my-4"
          imageUrl={props.imageUrl}
          alt={props.imageAlt}
        />
      </div>
      <Container>
        <Row>
          <Col md="3">
            {details}
            <div className="arrow-down-icon d-none d-lg-block">
              <img src={arrowDownIcon} alt="arrow down icon" />
            </div>
          </Col>
          <Col md="6" className="py-5 py-lg-0 offset-lg-3">
            <div className="casehistory-details">
              {!props.isMobile ? headingTitle : null}
              {props.description}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Details.propTypes = {
  title: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
  details: PropTypes.object.isRequired,
  imageUrl: PropTypes.string,
  imageAlt: PropTypes.string,
  description: PropTypes.string,
};

export default Details;
