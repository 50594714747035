import { graphql, useStaticQuery } from "gatsby";

const query = () => {
  return useStaticQuery(graphql`
    query CaseHistoryItems {
      allContentfulCaseHistoryList {
        nodes {
          node_locale
          vediAnche
        }
      }
      allContentfulCaseHistoryItem {
        nodes {
          node_locale
          customerName
          customerImage {
            gatsbyImageData
            file {
              url
            }
            title
          }
          customerAdditionalImage {
            gatsbyImageData
            title
            file {
              url
            }
          }
          customerDescription {
            customerDescription
          }
          customerLongDescriptionCol1 {
            raw
          }
          customerLongDescriptionCol2 {
            raw
          }
          customerDetails {
            key
            value
          }
          customerNumbers {
            rows {
              _0 {
                number
                value
              }
              _1 {
                number
                value
              }
            }
          }
          relatedCase {
            customerName
            customerLink
            customerDescription {
              customerDescription
            }
            customerImage {
              gatsbyImageData
              file {
                url
              }
            }
          }
        }
      }
    }
  `);
};

export default query;
