import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import CallToAction from "../../../CallToAction/CallToAction";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "./Related.scss";

const Related = (props) => {
  const handleBoxClick = () => {
    document.querySelector(".call-to-action").click();
  };

  return (
    <Container fluid>
      <div className="casehistory-related">
        <div className="heading">
          <p>{props.title}</p>
        </div>
        {typeof props.item !== "undefined" ? (
          <div className="item-wrapper">
            <div className="item" onClick={handleBoxClick}>
              <Row>
                <Col className="col-lg-6 col-md-12 col-12 p-0">
                  <div className="casehistory-related-item-image">
                    <GatsbyImage
                      image={getImage(props.item.customerImage)}
                      alt={props.item.customerName}
                    />
                  </div>
                </Col>
                <Col className="col-lg-6 col-md-12 col-12">
                  <div className="casehistory-related-item-content">
                    <p>{props.item.customerName}</p>
                    <div className="item-description">
                      {props.item.customerDescription.customerDescription}
                    </div>
                    <CallToAction
                      to={props.item.customerLink}
                      label="Case Study"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        ) : null}
      </div>
    </Container>
  );
};

Related.propTypes = {
  title: PropTypes.string,
  item: PropTypes.object.isRequired,
};

export default Related;
