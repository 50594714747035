import React from "react";
import "./Single.scss";
import { Image } from "./Image";
import { Details } from "./Details";
import { Numbers } from "./Numbers";
import { Description } from "./Description";
import { Related } from "./Related";
import query from "./query";
import PropTypes from "prop-types";
import {
  filterListDataByLanguage,
  filterDataByLanguage,
} from "../../../Utils/Language";

const Single = (props) => {
  const data = query();
  const contentHistoryItem = filterListDataByLanguage(
    props.lang,
    data.allContentfulCaseHistoryItem
  );
  const contentHistoryList = filterDataByLanguage(
    props.lang,
    data.allContentfulCaseHistoryList
  );
  const caseHistoryData = contentHistoryItem.find(
    (node) => node.customerName === props.customerName
  );

  return (
    <section className="casehistory-single">
      <Details
        title={caseHistoryData.customerName}
        isMobile={typeof window !== "undefined" && window.innerWidth < 810}
        imageUrl={
          caseHistoryData.customerImage ? caseHistoryData.customerImage : ""
        }
        imageAlt={
          caseHistoryData.customerImage
            ? caseHistoryData.customerImage.title
            : ""
        }
        details={
          caseHistoryData.customerDetails ? caseHistoryData.customerDetails : {}
        }
        description={
          caseHistoryData.customerDescription
            ? caseHistoryData.customerDescription.customerDescription
            : null
        }
      />
      <Image
        isVisible={true}
        isMobile={false}
        isFull={false}
        isFluid={false}
        className="d-none d-lg-block"
        imageUrl={
          caseHistoryData.customerImage ? caseHistoryData.customerImage : ""
        }
        alt={
          caseHistoryData.customerImage
            ? caseHistoryData.customerImage.title
            : ""
        }
      />
      <Numbers
        numbers={
          caseHistoryData.customerNumbers
            ? caseHistoryData.customerNumbers
            : null
        }
      />
      <Description
        col1={
          caseHistoryData.customerLongDescriptionCol1
            ? caseHistoryData.customerLongDescriptionCol1.raw
            : null
        }
        col2={
          caseHistoryData.customerLongDescriptionCol2
            ? caseHistoryData.customerLongDescriptionCol2.raw
            : null
        }
      />
      <Image
        isVisible={true}
        isFull={true}
        isFluid={true}
        imageUrl={
          caseHistoryData.customerAdditionalImage
            ? caseHistoryData.customerAdditionalImage
            : ""
        }
        alt={
          caseHistoryData.customerAdditionalImage
            ? caseHistoryData.customerAdditionalImage.title
            : ""
        }
      />
      <Related
        title={contentHistoryList.vediAnche}
        item={caseHistoryData.relatedCase ? caseHistoryData.relatedCase : null}
      />
    </section>
  );
};

Single.propTypes = {
  customerName: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
};

export default Single;
